<template>
  <div>
    <header>
      <nav>
        <ul>
          <li>
            <NuxtLink to="/">
              Explore
            </NuxtLink>
          </li>
        </ul>
      </nav>
    </header>
    <main class="center-content">
      <div class="auth-container">
        <LogoSimpleIndi />
        <slot />
        <div class="bottom-space" />
      </div>
    </main>
  </div>
</template>

<style lang="scss">
@property --a {
  syntax: '<angle>';
  inherits: false;
  initial-value: 0deg;
}
:root {
  --a: 20deg;
}
main {
  min-height: 100svh;
  .auth-container {
    width: min(calc(100vw - var(--base-size)), 55ch);
    text-align: center;

    .bottom-space {
      aspect-ratio: 724 / 203;
    }
    .input-box, .input-box .input-wrap, button {
      margin-inline: auto;
    }
    button {
      position: relative;
      // background: transparent;
      overflow: hidden;
      // color: black;
      margin-block: var(--padding-base);

      // &::after {
      //   content: "";
      //   position: absolute;
      //   inset: 0;
      //   background-color: white;
      //   mix-blend-mode: difference;
      //   z-index: 1;
      // }

      &:hover, &:focus {
        &::after {
          inset: 0;
          width: 100%;
          height: 100%;
          transform: none;
        }
      }
    }
  }
}

.card {
  background-color: white;
  padding: var(--padding-base);
  border-radius: var(--padding-big);
}

@keyframes rotate {
  from {
  --a:0deg;
  }
  to {
  --a:360deg;
  }
}
</style>
